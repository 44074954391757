html {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 10px; // setting the base font-size to 10px so from now on 1rem = 10px, no rem-to-px converter needed 🎉
}

body {
  font-size: 1.6rem; // set the default font size to 1.6rem (16px)
}

.header {
  padding: 2rem;
}

.iframe-container {
  height: 46.5rem;
  width: 32.2rem;
}

.footer {
  padding: 2rem;
}

.caller-link {
  padding: 1rem 1rem 0;
  &:last-child {
    padding-bottom: 1rem;
  }
}
